<template>
  <ul class="list" v-loading="loading">
    <CardItem v-for="item in list" :key="item.id" :info="item" />
  </ul>
</template>

<script>
import { apiCourseList } from '@/api/course'
import CardItem from '../components/cardItem'

export default {
  components: {
    CardItem
  },
  data() {
    return {
      list: [],
      loading: false
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      this.loading = true

      const { data } = await apiCourseList({
        page: 1,
        page_size: 999999
      })
      this.list = data.list || []

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
</style>
